import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Slider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { configurationUrl, createDeviceUrl, mainUrl } from "../costants";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import {
  Coffee,
  Cyclone,
  DirectionsRun,
  Error,
  Favorite,
  LocalCafe,
  Storm,
} from "@mui/icons-material";
import { deleteDevice, getDevice } from "../../../api/services/deviceService";
import CustomLoading from "../../custom/CustomLoading";

type ConfigurationSliderProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const ConfigurationSlider: React.FC<ConfigurationSliderProps> = ({
  setBreadcrumb,
}) => {
  const { id } = useParams(); //device-id
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState<any>({});
  useEffect(() => {
    getDevice(id!).then((res) => {
      if (res && res.installation) {
        setDevice({ ...res.installation });
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("firmwareParameters")}
      </Typography>,
    ]);
  }, []);

  const [values, setValues] = useState({
    thresholds: [18, 22, 28, 32],
    macinatore1: [1, 3.6, 6],
    macinatore2: [1, 3.6, 6],
    attraversamento: 15,
  });

  const [configuration, setConfiguration] = useState<any>({});
  useEffect(() => {
    setConfiguration(
      JSON.parse(localStorage.getItem("zerynth_idealcup_configuration") || "{}")
    );
    if (!localStorage.getItem("zerynth_idealcup_configuration")) {
      //navigate(configurationParamsUrl(id!));
    }
  }, []);

  const handleSubmit = () => {
    const tmp = {
      ...configuration,
    };
    tmp.macinatore1 = values.macinatore1.map((n: number) => n * 1000);
    tmp.macinatore2 = values.macinatore2.map((n: number) => n * 1000);
    tmp.attraversamento = values.attraversamento;
    tmp.thresholds = values.thresholds.map((n: number) => n * 1000);
    console.log("CONFIGURATION ", tmp);
    localStorage.setItem("zerynth_idealcup_configuration", JSON.stringify(tmp));
    navigate(configurationUrl(id!));
  };

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("updateParameters")}`}
          goBack={() => {
            deleteDevice(id!);
            navigate(createDeviceUrl);
          }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <b>{t("grinder")} 1:</b> {t("grinderParamsTitle")}
                <CustomList
                  dense
                  listElement={[
                    {
                      startIcon: <Error />,
                      primaryText: t("grinderParamsSubTitle1"),
                    },
                    {
                      startIcon: <Storm />,
                      primaryText: t("grinderParamsSubTitle2"),
                    },
                    {
                      startIcon: <Cyclone />,
                      primaryText: t("grinderParamsSubTitle3"),
                    },
                    {
                      startIcon: <Error />,
                      primaryText: t("grinderParamsSubTitle4"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "24px" }}>
                <Slider
                  step={0.1}
                  getAriaLabel={() => "Soglie caffè"}
                  value={values.macinatore1}
                  onChange={(e, newValue: any) => {
                    setValues({ ...values, macinatore1: newValue as number[] });
                  }}
                  getAriaValueText={(value: number) => `${value} sec`}
                  valueLabelFormat={(value) => `${value} sec`}
                  valueLabelDisplay="on"
                  max={10}
                />
              </Grid>
              {device && device.doppio_macinatore && (
                <Grid item xs={12}>
                  <b>{t("grinder")} 2:</b> {t("grinderParamsTitle")}
                  <CustomList
                    dense
                    listElement={[
                      {
                        startIcon: <Error />,
                        primaryText: t("grinderParamsSubTitle1"),
                      },
                      {
                        startIcon: <Storm />,
                        primaryText: t("grinderParamsSubTitle2"),
                      },
                      {
                        startIcon: <Cyclone />,
                        primaryText: t("grinderParamsSubTitle3"),
                      },
                      {
                        startIcon: <Error />,
                        primaryText: t("grinderParamsSubTitle4"),
                      },
                    ]}
                  />
                </Grid>
              )}
              {device && device.doppio_macinatore && (
                <Grid item xs={12} style={{ paddingTop: "24px" }}>
                  <Slider
                    step={0.1}
                    getAriaLabel={() => "Soglie caffè"}
                    value={values.macinatore2}
                    onChange={(e, newValue: any) => {
                      setValues({
                        ...values,
                        macinatore2: newValue as number[],
                      });
                    }}
                    getAriaValueText={(value: number) => `${value} sec`}
                    valueLabelFormat={(value) => `${value} sec`}
                    valueLabelDisplay="on"
                    max={10}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {t("bartenderParamsTitle1")}
                <b>{t("bartender")}</b>
                {t("bartenderParamsTitle2")}
                <CustomList
                  dense
                  listElement={[
                    {
                      startIcon: <DirectionsRun />,
                      primaryText: t("bartenderParamsSubTitle1"),
                    },
                    {
                      startIcon: <Error />,
                      primaryText: t("bartenderParamsSubTitle2"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "24px" }}>
                <Slider
                  getAriaLabel={() => "Soglie caffè"}
                  value={values.attraversamento}
                  onChange={(e, newValue) => {
                    setValues({
                      ...values,
                      attraversamento: newValue as number,
                    });
                  }}
                  getAriaValueText={(value: number) => `${value} sec`}
                  valueLabelFormat={(value) => `${value} sec`}
                  valueLabelDisplay="on"
                  max={60}
                />
              </Grid>
              <Grid item xs={12}>
                {t("machineParamsTitle1")}
                <b> {t("machineLowerCase")}</b>
                {t("machineParamsTitle2")}
                <CustomList
                  dense
                  listElement={[
                    {
                      startIcon: <Error />,
                      primaryText: t("machineParamsSubTitle1"),
                    },
                    {
                      startIcon: <Coffee />,
                      primaryText: t("machineParamsSubTitle2"),
                    },
                    {
                      startIcon: <Favorite />,
                      primaryText: t("machineParamsSubTitle3"),
                    },
                    {
                      startIcon: <LocalCafe />,
                      primaryText: t("machineParamsSubTitle4"),
                    },
                    {
                      startIcon: <Error />,
                      primaryText: t("machineParamsSubTitle5"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "24px" }}>
                <Slider
                  step={0.1}
                  getAriaLabel={() => "Soglie caffè"}
                  value={values.thresholds}
                  onChange={(e, newValue: any) => {
                    setValues({ ...values, thresholds: newValue as number[] });
                  }}
                  getAriaValueText={(value: number) => `${value} sec`}
                  valueLabelFormat={(value) => `${value} sec`}
                  valueLabelDisplay="on"
                  max={60}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  thresholds: [18, 22, 28, 32],
                  macinatore1: [1, 3.6, 6],
                  macinatore2: [1, 3.6, 6],
                  attraversamento: 15,
                })
              }
              type="outlined"
              confirm
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationSlider;
