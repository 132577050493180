import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Slider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { mainUrl, roles } from "../costants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomSelect from "../../custom/CustomSelect";
import { getDevices } from "../../../api/services/deviceService";
import CustomLoading from "../../custom/CustomLoading";
import CustomList from "../../custom/CustomList";
import {
  Close,
  Coffee,
  Cyclone,
  DirectionsRun,
  Error,
  Favorite,
  LocalCafe,
  Storm,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import {
  init,
  getZfs,
  commit,
  uploadFiles,
  getZfsFile,
  sendAlarmToRoasting,
  checkZfsStatus,
} from "../../../api/services/configuratorService";
import {
  prepareConfigJson,
  prepareNetTestJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../custom/utils";
import CustomIconButton from "../../custom/CustomIconButton";
import { getLastData } from "../../../api/services/tsservice";
import CustomFeedback from "../../custom/CustomFeedback";

type UpdateParamsProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  role: string;
};

const UpdateParams: React.FC<UpdateParamsProps> = ({ setBreadcrumb, role }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [devices, setDevices] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [warningVisible, setWarningVisible] = useState<boolean>(false);
  const [values, setValues] = useState({
    device: "",
    amount: "",
    productivity: "",
    thresholds: [18, 22, 28, 32],
    macinatore1: [1, 3.6, 6],
    macinatore2: [1, 3.6, 6],
    attraversamento: 15,
    price1: 0,
    price2: 0,
    amount1: 0,
    amount2: 0,
    macchina_w_thresh: 0,
    macina1_w_thresh: 0,
    macina2_w_thresh: 0,
  });
  const [baseValues, setBaseValues] = useState({
    thresholds: [18, 22, 28, 32],
    macinatore1: [1, 3.6, 6],
    macinatore2: [1, 3.6, 6],
    attraversamento: 15,
  });
  const [net, setNet] = useState<any>({});
  const [snackbarKeys, setSnackbarKeys] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("updateParameters")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    // Funzione per controllare il localStorage e chiamare l'endpoint
    const checkLocalStorage = () => {
      const tmp = localStorage.getItem("zerynth_deleting_device");
      if (!tmp) {
        clearInterval(interval); // Pulisce l'interval dopo il primo controllo
        getDevices().then((res) => {
          if (res && res.installations) {
            setDevices([...res.installations]);
          }
          setLoading(false);
        });
      }
    };

    // Esegui la funzione di controllo ogni secondo
    const interval = setInterval(checkLocalStorage, 1000);

    // Esegui il controllo iniziale quando il componente viene montato
    checkLocalStorage();

    // Pulisci l'interval quando il componente viene smontato
    return () => {
      clearInterval(interval);
    };
  }, []); // L'array vuoto come dipendenza indica che questo effetto si verifica solo una volta

  useEffect(() => {
    if (values.device) {
      getZfs(values.device).then((res: any) => {
        if (res && res.files) {
          const netFile = res.files.find(
            (file: any) => file.path === "net.json"
          );
          const paramsFile = res.files.find(
            (file: any) => file.path === "params.json"
          );
          if (netFile) {
            getZfsFile(values.device, netFile.id).then((resFile) => {
              setNet(resFile);
            });
          }
          if (paramsFile) {
            getZfsFile(values.device, paramsFile.id).then((resFile: any) => {
              getLastData(values.device, "z", "*").then((res) => {
                if (
                  res &&
                  res.result &&
                  res.result.values &&
                  res.result.values[0].value &&
                  res.result.values[0].value.bar &&
                  res.result.values[0].value.macina_1 &&
                  res.result.values[0].value.macina_2 &&
                  res.result.values[0].value.macchina
                ) {
                  const values = res.result.values[0].value;
                  setBaseValues({
                    thresholds: values.macchina[3].map((n: number) => n / 1000),
                    macinatore1: values.macina_1[3].map(
                      (n: number) => n / 1000
                    ),
                    macinatore2: values.macina_2[3].map(
                      (n: number) => n / 1000
                    ),
                    attraversamento: values.bar,
                  });

                  setValues((prev) => ({
                    ...prev,
                    price1: resFile.macina_1.costo,
                    price2: resFile.macina_2.costo,
                    amount1: resFile.macina_1["g/dose"],
                    amount2: resFile.macina_2["g/dose"],
                    thresholds: values.macchina[3].map((n: number) => n / 1000),
                    macinatore1: values.macina_1[3].map(
                      (n: number) => n / 1000
                    ),
                    macinatore2: values.macina_2[3].map(
                      (n: number) => n / 1000
                    ),
                    attraversamento: values.bar,
                    macchina_w_thresh: resFile.macchina.w_thresh,
                    macina1_w_thresh: resFile.macina_1.w_thresh,
                    macina2_w_thresh: resFile.macina_2.w_thresh,
                  }));
                }
              });
            });
          }
        }
      });
    }
  }, [values.device]);

  const handleSubmit = useCallback(async () => {
    if (values.device) {
      const netFile = {
        file: new File(
          [new TextEncoder().encode(JSON.stringify(net))] as BlobPart[],
          "net.json"
        ),
      };
      const paramsFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                prepareParamsJson(
                  values.price1,
                  values.price2,
                  values.amount1,
                  values.amount2,
                  values.thresholds.map((n: number) => n * 1000),
                  values.macinatore1.map((n: number) => n * 1000),
                  values.macinatore2.map((n: number) => n * 1000),
                  values.attraversamento,
                  values.macchina_w_thresh,
                  values.macina1_w_thresh,
                  values.macina2_w_thresh
                )
              )
            ),
          ] as BlobPart[],
          "params.json"
        ),
      };
      const netTestFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareNetTestJson)),
          ] as BlobPart[],
          "net_test.json"
        ),
      };
      const sensorsFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
          ] as BlobPart[],
          "sensors.json"
        ),
      };
      const configFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
          ] as BlobPart[],
          "config.json"
        ),
      };
      init(values.device)
        .then(
          async () =>
            await uploadFiles(values.device, [
              netFile,
              paramsFile,
              sensorsFile,
              configFile,
              netTestFile,
            ]).then(async (res: any) => {
              if (res && res.files) {
                await commit(values.device, true).then((r: any) => {
                  if (r && r.version) {
                    const tmpDevice = devices.find(
                      (device) => device.id === values.device
                    );
                    if (role === roles.customer && tmpDevice) {
                      sendAlarmToRoasting(
                        tmpDevice?.azienda_email || "",
                        tmpDevice?.id || "",
                        tmpDevice?.name || ""
                      );
                    }
                    enqueueSnackbar(t("parametersSuccess"), {
                      variant: "success",
                    });
                    setValues({
                      device: "",
                      amount: "",
                      productivity: "",
                      thresholds: [18, 22, 28, 32],
                      macinatore1: [1, 3.6, 6],
                      macinatore2: [1, 3.6, 6],
                      attraversamento: 15,
                      price1: 0,
                      price2: 0,
                      amount1: 0,
                      amount2: 0,
                      macchina_w_thresh: 0,
                      macina1_w_thresh: 0,
                      macina2_w_thresh: 0,
                    });
                  } else {
                    enqueueSnackbar(
                      t("parametersError") + " " + res?.err?.message,
                      {
                        variant: "error",
                      }
                    );
                  }
                });
              } else {
                enqueueSnackbar(
                  t("parametersError") + " " + res?.err?.message,
                  {
                    variant: "error",
                  }
                );
              }
            })
        )
        .catch((err: any) => {
          console.log("ERROR", err);
          enqueueSnackbar(t("parametersError"), {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar(t("selectDeviceError"), {
        variant: "error",
      });
    }
  }, [values, net]);

  useEffect(() => {
    if (warningVisible && snackbarKeys.length === 0) {
      const key = enqueueSnackbar(`${t("warningParametersUpdate")}`, {
        variant: "error",
        persist: true,
        action: (key) => (
          <CustomIconButton
            icon={<Close />}
            type="outlined"
            size="small"
            onClick={() => closeSnackbar(key)}
          />
        ),
      });
      setSnackbarKeys((prevKeys) => [...prevKeys, key]);
    } else {
      if (!warningVisible) {
        snackbarKeys.forEach((key) => {
          closeSnackbar(key);
        });
        setSnackbarKeys([]);
      }
    }
  }, [warningVisible, snackbarKeys]);

  const check2Grinder = (devId: string) => {
    const dev = devices.find((device) => device.id === devId);
    if (dev) {
      return dev?.doppio_macinatore;
    }
    return false;
  };

  useEffect(() => {
    console.log("VALUES", values);
  }, [values]);

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(values.device).then((res) => {
        if (res && res.job && res.job.status) {
          if (res.job.status === "pending") {
            setRunningZfs(true);
          } else {
            setRunningZfs(false);
          }
        }
      });
    };
    if (values.device) {
      checkStatus();
      const intervalId = setInterval(checkStatus, 30000);
      return () => clearInterval(intervalId);
    }
  }, [values.device]);

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={`${t("updateParameters")}`}
          goBack={() => navigate(-1)}
        />
      </Grid>
      {runningZfs && (
        <Grid item xs={12}>
          <CustomFeedback label={t("workInProgress")} type="warning" />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomSelect
                  label={`${t("selectDevice")}`}
                  value={values.device}
                  handleChange={(e) =>
                    setValues({ ...values, device: e.target.value })
                  }
                  options={[
                    ...devices.map((device) => ({
                      value: device.id,
                      label: device.name,
                    })),
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <b>{t("grinder")} 1:</b> {t("grinderParamsTitle")}
                <CustomList
                  dense
                  listElement={[
                    {
                      startIcon: <Error />,
                      primaryText: t("grinderParamsSubTitle1"),
                    },
                    {
                      startIcon: <Storm />,
                      primaryText: t("grinderParamsSubTitle2"),
                    },
                    {
                      startIcon: <Cyclone />,
                      primaryText: t("grinderParamsSubTitle3"),
                    },
                    {
                      startIcon: <Error />,
                      primaryText: t("grinderParamsSubTitle4"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "24px" }}>
                <Slider
                  step={0.1}
                  getAriaLabel={() => "Soglie caffè"}
                  value={values.macinatore1}
                  onChange={(e, newValue: any) => {
                    setValues({ ...values, macinatore1: newValue as number[] });
                    //check if the values are differentemore than three units from baseValues
                    if (
                      Math.abs(newValue[0] - baseValues.macinatore1[0]) > 3 ||
                      Math.abs(newValue[1] - baseValues.macinatore1[1]) > 3 ||
                      Math.abs(newValue[2] - baseValues.macinatore1[2]) > 3
                    ) {
                      setWarningVisible(true);
                    } else {
                      setWarningVisible(false);
                    }
                  }}
                  getAriaValueText={(value: number) =>
                    `${value.toFixed(1)} sec`
                  }
                  valueLabelFormat={(value) => `${value.toFixed(1)} sec`}
                  valueLabelDisplay="on"
                  max={10}
                />
              </Grid>
              {values.device && check2Grinder(values.device) && (
                <Grid item xs={12}>
                  <b>{t("grinder")} 2:</b> {t("grinderParamsTitle")}
                  <CustomList
                    dense
                    listElement={[
                      {
                        startIcon: <Error />,
                        primaryText: t("grinderParamsSubTitle1"),
                      },
                      {
                        startIcon: <Storm />,
                        primaryText: t("grinderParamsSubTitle2"),
                      },
                      {
                        startIcon: <Cyclone />,
                        primaryText: t("grinderParamsSubTitle3"),
                      },
                      {
                        startIcon: <Error />,
                        primaryText: t("grinderParamsSubTitle4"),
                      },
                    ]}
                  />
                </Grid>
              )}
              {values.device && check2Grinder(values.device) && (
                <Grid item xs={12} style={{ paddingTop: "24px" }}>
                  <Slider
                    step={0.1}
                    getAriaLabel={() => "Soglie caffè"}
                    value={values.macinatore2}
                    onChange={(e, newValue: any) => {
                      setValues({
                        ...values,
                        macinatore2: newValue as number[],
                      });
                      //check if the values are differentemore than three units from baseValues
                      if (
                        Math.abs(newValue[0] - baseValues.macinatore2[0]) > 3 ||
                        Math.abs(newValue[1] - baseValues.macinatore2[1]) > 3 ||
                        Math.abs(newValue[2] - baseValues.macinatore2[2]) > 3
                      ) {
                        setWarningVisible(true);
                      } else {
                        setWarningVisible(false);
                      }
                    }}
                    getAriaValueText={(value: number) =>
                      `${value.toFixed(1)} sec`
                    }
                    valueLabelFormat={(value) => `${value.toFixed(1)} sec`}
                    valueLabelDisplay="on"
                    max={10}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {t("bartenderParamsTitle1")}
                <b>{t("bartender")}</b>
                {t("bartenderParamsTitle2")}
                <CustomList
                  dense
                  listElement={[
                    {
                      startIcon: <DirectionsRun />,
                      primaryText: t("bartenderParamsSubTitle1"),
                    },
                    {
                      startIcon: <Error />,
                      primaryText: t("bartenderParamsSubTitle2"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "24px" }}>
                <Slider
                  getAriaLabel={() => "Soglie caffè"}
                  value={values.attraversamento}
                  onChange={(e, newValue) => {
                    setValues({
                      ...values,
                      attraversamento: newValue as number,
                    });
                    //check if the values are differentemore than three units from baseValues
                    if (
                      Math.abs(Number(newValue) - baseValues.attraversamento) >
                      3
                    ) {
                      setWarningVisible(true);
                    } else {
                      setWarningVisible(false);
                    }
                  }}
                  getAriaValueText={(value: number) => `${value} sec`}
                  valueLabelFormat={(value) => `${value} sec`}
                  valueLabelDisplay="on"
                  max={60}
                />
              </Grid>
              <Grid item xs={12}>
                {t("machineParamsTitle1")}
                <b> {t("machineLowerCase")}</b>
                {t("machineParamsTitle2")}
                <CustomList
                  dense
                  listElement={[
                    {
                      startIcon: <Error />,
                      primaryText: t("machineParamsSubTitle1"),
                    },
                    {
                      startIcon: <Coffee />,
                      primaryText: t("machineParamsSubTitle2"),
                    },
                    {
                      startIcon: <Favorite />,
                      primaryText: t("machineParamsSubTitle3"),
                    },
                    {
                      startIcon: <LocalCafe />,
                      primaryText: t("machineParamsSubTitle4"),
                    },
                    {
                      startIcon: <Error />,
                      primaryText: t("machineParamsSubTitle5"),
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "24px" }}>
                <Slider
                  step={0.1}
                  getAriaLabel={() => "Soglie caffè"}
                  value={values.thresholds}
                  onChange={(e, newValue: any) => {
                    setValues({ ...values, thresholds: newValue as number[] });
                    //check if the values are differentemore than three units from baseValues
                    if (
                      Math.abs(newValue[0] - baseValues.thresholds[0]) > 3 ||
                      Math.abs(newValue[1] - baseValues.thresholds[1]) > 3 ||
                      Math.abs(newValue[2] - baseValues.thresholds[2]) > 3 ||
                      Math.abs(newValue[3] - baseValues.thresholds[3]) > 3
                    ) {
                      setWarningVisible(true);
                    } else {
                      setWarningVisible(false);
                    }
                  }}
                  getAriaValueText={(value: number) =>
                    `${value.toFixed(1)} sec`
                  }
                  valueLabelFormat={(value) => `${value.toFixed(1)} sec`}
                  valueLabelDisplay="on"
                  max={60}
                />
              </Grid>
            </Grid>
          }
          actions={[
            <CustomButton
              fullWidth
              label={t("reset")}
              onClick={() =>
                setValues({
                  productivity: "",
                  device: "",
                  amount: "",
                  thresholds: [18, 22, 28, 32],
                  macinatore1: [1, 3.6, 6],
                  macinatore2: [1, 3.6, 6],
                  attraversamento: 15,
                  price1: 0,
                  price2: 0,
                  amount1: 0,
                  amount2: 0,
                  macchina_w_thresh: 0,
                  macina1_w_thresh: 0,
                  macina2_w_thresh: 0,
                })
              }
              type="outlined"
              confirm
            />,
            <CustomButton
              fullWidth
              label={t("ok")}
              onClick={handleSubmit}
              type="contained"
              disabled={runningZfs}
            />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default UpdateParams;
